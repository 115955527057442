import { createBrowserRouter, Navigate } from "react-router-dom";
import Login from "./views/Login";
import NotFound from "./views/NotFound";
import DefaultLayout from "./components/DefaultLayout";
import GuestLayout from "./components/GuestLayout";
import Dashboard from "./views/Dashboard";
import Space from "./views/Space";
import Spaces from "./views/Spaces";
import Maps from "./views/Maps";
import Map from "./views/Map";
import Labels from "./views/Labels";
import Label from "./views/Label";
import FeedbackQuestions from "./views/FeedbackQuestions";
import FeedbackQuestion from "./views/FeedbackQuestion";

const router = createBrowserRouter([
    {   
        path:'/',
        element: <DefaultLayout/>,
        children:[
            {
                path:'/',
                element: <Navigate to={'/spaces'}/>
            },
            {
                path:'/dashboard',
                element: <Dashboard/>
            },
            {
                path:'/spaces',
                element:<Spaces/>
            }
            ,
            {
                path:'/space/:space_id',
                element:<Space/>
            },
            {
                path:'/maps',
                element:<Maps/>
            }
            ,
            {
                path:'/map/:map_id',
                element:<Map/>
            },
            {
                path:'/labels',
                element:<Labels/>
            }
            ,
            {
                path:'/label/:label_id',
                element:<Label/>
            },
            {
                path:'/feedback-question',
                element:<FeedbackQuestions/>
            }
            ,
            {
                path:'/feedback-question/:question_id',
                element:<FeedbackQuestion/>
            }
        ]
    },
    {
        path:'/',
        element: <GuestLayout/>,
        children:[
            {
                path:'/login',
                element:<Login/>
            }
        ]

    },
    {
        path:'*',
        element:<NotFound/>
    }
]);

export default router;