import { Component } from "react";

// MUI Components 
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChecklistIcon from '@mui/icons-material/Checklist';
import { Link } from "react-router-dom";


const Sidebar = () => {
    return (
<Box sx={{ width: '100%', height: 'calc(100vh - 64px)', maxWidth: 220, boxShadow:'3px 3px 3px lightgray' }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem >
            <ListItemButton component={Link}  to={'/spaces'}>
              <ListItemIcon>
                <ChecklistIcon />
              </ListItemIcon>
              <ListItemText primary="Spaces"/>
            </ListItemButton>
          </ListItem>
          <ListItem >
            <ListItemButton component={Link}  to={'/maps'}>
              <ListItemIcon>
                <ChecklistIcon />
              </ListItemIcon>
              <ListItemText primary="Maps"/>
            </ListItemButton>
          </ListItem>
          <ListItem >
            <ListItemButton component={Link}  to={'/feedback-question'}>
              <ListItemIcon>
                <ChecklistIcon />
              </ListItemIcon>
              <ListItemText primary="Feedback Questions"/>
            </ListItemButton>
          </ListItem>
          <ListItem >
            <ListItemButton component={Link}  to={'/labels'}>
              <ListItemIcon>
                <ChecklistIcon />
              </ListItemIcon>
              <ListItemText primary="Labels"/>
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Box>
    );
}
export default Sidebar;